@import "../../../../scss/_variables";

.promo {
  clip-path: polygon(0 0, 1600px 0, 1600px 80%, 0 100%);
}

.promo {
  background-size: cover;
  color: #fff;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}

.containerQuik {
  margin-left: 90px;
  margin-right: 90px;
  @media screen and (max-width: 500px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.homeText {
  font-family: $font-secondary;
  font-size: 23px;
  line-height: 30px;
  color: $primary;
  white-space: pre-line;
  text-align: center;
}

.rowButton {
  height: 100px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid $primary;
  padding: 40px;
}

.rowButtonText {
  font-family: $font-primary;
  font-size: 28px;
  line-height: 21px;
  color: $primary;
}

.normalText {
  font-family: $font-secondary;
  font-size: 23px;
  line-height: 23px;
  color: $primary;
  white-space: pre-line;
}

.titleText {
  font-family: $font-primary;
  font-size: 31px;
  line-height: 28px;
  color: $primary;
}
.homeTitleText {
  font-family: $font-primary;
  font-size: 50px;
  line-height: 28px;
  color: $primary;
}

@media screen and (max-width: 900px) {
  .rowButtonText {
    font-family: $font-primary;
    font-size: 28px;
    line-height: 0px;
    color: $primary;
  }

  .rowButton {
    height: 100px;
    text-align: center;
    border-radius: 10px;
    border: 2px solid $primary;
    padding: 20px;
  }
  .titleText {
    font-family: $font-primary;
    font-size: 23px;
    line-height: 27px;
    letter-spacing: -0.005em;
    color: $primary;
  }
  .normalText {
    font-family: $font-secondary;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.005em;
    color: $primary;
    text-align: justify;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .normalText {
    font-family: $font-secondary;
    font-size: 23px;
    line-height: 23px;
    letter-spacing: -0.005em;
    color: $primary;
    text-align: justify;
    margin-bottom: 20px;
  }

  .titleText {
    font-family: $font-primary;
    font-size: 31px;
    line-height: 28px;
    letter-spacing: -0.005em;
    color: $primary;
    text-align: center;
  }

  .homeTitleText {
    font-family: $font-primary;
    font-size: 41px;
    line-height: 28px;
    color: $primary;
  }
}
