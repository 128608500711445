@import "../../../../scss/_variables";

.headerPrincipalPost {
  margin-top: 110px;
  margin-left: 35px;
  margin-right: 35px;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px;
  @media screen and (max-width: 500px) {
    height: 315px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.backgroundImg {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.infoHeader {
  background-color: $primary;
  height: 500px;
  width: 40%;
  float: right;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  top:0px;
  right:0px;
  @media screen and (max-width: 500px) {
    height: 40%;
    width: 100%;
    bottom:0px;
    right:0px;
    top: auto;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
  }
}

.infoHeaderTitle{
  font-family: $font-secondary;
  font-size: 64px;
  line-height: 64px;
  color: white;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  @media screen and (max-width: 500px) {
    text-align: center;
    margin-top: 20px;
    font-size: 25px;
    line-height: 25px;
  }
}

.infoHeaderText{
  font-family: $font-primary;
  font-size: 25px;
  line-height: 25px;
  color: white;
  text-align: center;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  @media screen and (max-width: 500px) {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    line-height: 16px;
  }
}

.infoHeaderButton {
  height: 60px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid white;
  padding: 13px;
  width: 150px;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.infoHeaderButtonText {
  font-family: $font-primary;
  font-size: 20px;
  line-height: 29px;
  color: white;
}
