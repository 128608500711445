@import "../../../../scss/_variables";

.rowTitle {
  height: 30px;
  border-radius: 10px;
  background-color: $primary;
  margin-bottom: 10px;
}

.rowLogo {
  display: block;
  margin: auto;
  padding: 2px;
  height: 30px;
}

.containerRRSS {
  margin-left: 20px;
  margin-right: 20px;
}

.rssImg {
  width: 100%;
}

.rssRow {
  margin: 3px !important;
}

.nopadding {
  padding: 5px !important;
}

@media screen and (max-width: 500px) {
  .rssImg {
    height: 100%;
    width: 100%;
  }
}
