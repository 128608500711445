@import "../../scss/_variables";

.logoCarta {
  display: block;
  margin: auto;
  margin-top: 100px;
  width: 20%;
}

.normalTextFooter {
  font-family: $font-secondary;
  font-size: 23px;
  line-height: 23px;
  color: $primary;
  padding-left: 10%;
  padding-right: 10%;
  text-align: justify;
}

.rowButtonMenuQR {
  height: 70px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid $primary;
  padding: 20px;
  margin: auto;
}

.rowButtonTextQR {
  font-family: $font-primary;
  font-size: 18px;
  line-height: 21px;
  color: $primary;
}

@media screen and (max-width: 500px) {
  .logoCarta {
    display: block;
    margin: auto;
    margin-top: 80px;
    width: 50%;
  }
}
