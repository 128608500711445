@import "../../scss/_variables";
.menuNav {
  background-color: white;
}

.menuNavText {
  font-family: $font-primary;
  font-size: 28px;
  color: $primary;
  margin-bottom: 0px !important;
  cursor: pointer;
}

.logoImg {
  height: 80px;
  width: 177px;
  margin-left: 25px;
}

.menuItems {
  margin-right: 25px;
}

@media screen and (max-width: 500px) {
  .logoImg {
    height: 50px;
    width: 111px;
    margin-left: 0px;
  }
  .menuItems {
    margin-right: 0px;
  }
}
