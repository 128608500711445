@import "../../scss/_variables";

.map {
  height: 300px;
}

.normalTextRest {
  font-family: $font-secondary;
  font-size: 23px;
  line-height: 23px;
  color: $primary;
}

.titleTextRest {
  font-family: $font-primary;
  font-size: 31px;
  line-height: 30px;
  color: $primary;
}
