@import "../../scss/_variables";

.float {
  visibility: hidden;
  width: 0px;
  height: 0px;
  bottom: 0px;
}

@media screen and (max-width: 500px) {
  .float {
    visibility: visible;
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #79181e;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }

  .my-float {
    margin-top: 16px;
  }
}
