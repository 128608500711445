@import "../../scss/_variables";

.carousel-item-img {
  display: block !important;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 300px;
}

.prev-button {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: no-repeat 50% / 100% 100%;
  background-image: url("../../images/Arrow_left.png");
}

.next-button {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: no-repeat 50% / 100% 100%;
  background-image: url("../../images/Arrow_right.png");
}

.specialitiesRiceName {
  font-family: $font-primary;
  font-size: 28px;
  line-height: 21px;
  color: #9a9f8c;
}

@media screen and (max-width: 500px) {
  .carousel-item-img {
    display: block !important;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 270px;
  }
}
