@import "../../scss/_variables";

.promo {
  clip-path: polygon(0 0, 1600px 0, 1600px 80%, 0 100%);
}

.promo {
  background-size: cover;
  color: #fff;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}

.specialitiesText {
  font-family: $font-primary;
  font-size: 28px;
  line-height: 21px;
  color: $primary;
}

.showMore {
  color: $primary;
  font-family: $font-secondary;
}
