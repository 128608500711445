@import "../../scss/_variables";

.img1 {
  height: 290px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px;
}

.titleTextPost {
  font-family: $font-primary;
  font-size: 31px;
  line-height: 30px;
  color: $primary;
  text-align: center;
  margin-top: 40px;
  @media screen and (max-width: 500px) {
    text-align: justify;
    margin-top: 20px;
  }
}

.normalTextPost {
  font-family: $font-secondary;
  font-size: 23px;
  line-height: 23px;
  color: $primary;
  white-space: pre-line;
  text-align: justify;
}

.rightTextPost {
  font-family: $font-secondary;
  font-size: 23px;
  line-height: 23px;
  color: $black;
  white-space: pre-line;
  text-align: right;
}

.imageTextPost {
  font-family: $font-secondary;
  font-size: 20px;
  line-height: 20px;
  color: $black;
  white-space: pre-line;
  text-align: right;
  margin-top: 10px;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.postContent {
  width: 890px;
  margin: auto;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
