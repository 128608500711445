.change-img-local-1 {
  content: url("../../../../images/LT_LOCAL_01_TDP.jpg");
}
.change-img-local-2 {
  content: url("../../../../images/LT_LOCAL_02_TDP.jpg");
}

.sliderLocal {
  margin-top: 110px;
  margin-left: 20px;
  margin-right: 20px;
}

.carousel-inner {
  border-radius: 10px;
  border: 1px solid white;
}

@media screen and (max-width: 500px) {
  .change-img-local-1 {
    content: url("../../../../images/LT_LOCAL_01_TDP_SM.jpg");
  }
  .change-img-local-2 {
    content: url("../../../../images/LT_LOCAL_02_TDP_SM.jpg");
  }
  .sliderLocal {
    margin-top: 90px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
