.menuQR {
  padding: 10px;
  background-color: #62252c;
}

.table {
  background-color: white;
}

.thead {
  font-size: 18px;
  font-weight: 800;
  border-bottom: 3px solid;
  border-top-width: 10px;
  border-top-style: solid;
  border-color: #62252c;
}

.tbody {
  font-size: 14px;
}

.icon-image {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.tabs {
  margin-top: 100px;
}

.nav-tabs a {
  color: black;
}

.logo {
  height: 50px;
  width: 100px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.language {
  max-width: 50px;
}

.language-row {
  position: relative;
  float: right;
}

.table th,
.table td {
  padding: 0.35rem;
  font-size: small;
}

.footer {
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
}

.footer p {
  font-weight: 900;
}

.footer a {
  text-decoration: underline;
}

.btn-light {
  line-height: 0.7 !important;
}

.addButton {
  margin: 5px;
}

.logoutButton {
  margin-left: 5px;
}

.alert-shown {
  opacity: 1;
  position: fixed !important;
  bottom: 20px;
  left: 20px;
  z-index: 100;
}

.alert-hidden {
  opacity: 0;
}

.modal-hidden {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-shown {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

