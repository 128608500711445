.Delicious_area {
  .tablist_menu {
    margin-top: 20px;
    border-bottom: 1px solid #e5e8ea;
    margin-bottom: 60px;
    .nav {
      margin-bottom: 0px;
      li {
        margin: 0 25px;
        @media #{$mobile_device} {
          margin: 0 4px;
          margin-top: 20px;
        }
        a {
          padding: 0 12px 27px 12px;
          @include transition(0.3s);
          .single_menu {
            .icon {
              i {
                font-size: 50px;
                color: #596672;
              }
            }
            h4 {
              margin-bottom: 0;
              font-size: 26px;
              font-weight: 400;
              color: #596672;
              font-family: $font-primary;
            }
          }
          &.active {
            position: relative;
            .single_menu {
              .icon {
                i {
                  color: $primary;
                }
              }
              h4 {
                color: $primary;
              }
            }
            &:before {
              position: absolute;
              left: 0;
              width: 100%;
              height: 2px;
              content: "";
              background: $primary;
              bottom: 0;
            }
          }
        }
      }
    }
  }
  .single_delicious {
    margin-bottom: 50px;
    @media #{$mobile_device} {
      display: block !important;
    }
    @media #{$tablet_device} {
      display: block !important;
    }
    .thumb {
      @include border-radius(10px);
      img {
        @include border-radius(10px);
        height: 133px;
        width: 200px;
      }
    }
    .info {
      padding-left: 28px;
      @media #{$mobile_device} {
        padding-left: 0;
        margin-bottom: 15px;
        margin-top: 20px;
      }
      @media #{$tablet_device} {
        padding-left: 0;
        margin-top: 30px;
      }
      h3 {
        @media #{$mobile_device} {
          text-align: center;
        }
        font-family: $font-primary;
        font-size: 24px;
        line-height: 21px;
        color: $primary;
      }
      p {
        @media #{$mobile_device} {
          text-align: center;
        }
        font-family: $font-secondary;
        font-size: 18px;
        line-height: 23px;
        color: gray;
        margin-bottom: 0;
        margin-top: 15px;
        margin-bottom: 16px;
      }
      span {
        display: block;
        font-weight: 400;
        font-size: 26px;
        color: $primary;
      }
    }
  }
}
