@import "../../scss/_variables";

.footerMain {
  background-color: $primary;
  padding-top: 30px;
  padding-left: 20%;
  padding-right: 20%;
}

.socialImg {
  margin-right: 10px;
  height: 40px;
  width: 40px;
}

.socialImages {
  display: block;
  margin: auto;
}

.linkText {
  font-family: $font-primary;
  font-size: 30px;
  line-height: 30px;
  color: white;
}

.footerText {
  padding: 5px;
  margin-top: 10px;
  font-size: 13px;
  line-height: 21px;
  color: white;
  font-family: $font-secondary;
  font-weight: 800;
}

.createdText {
  font-size: 13px;
  line-height: 21px;
  color: white;
  font-family: $font-secondary;
  font-weight: 800;
}
.createContainer {
  width: 100%;
  text-align: center;
}

.logoFooter {
  height: 150px;
  width: 80px;
  margin: 0 auto;
  margin-bottom: 10px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.rowButtonFooter {
  height: 60px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid white;
  padding: 13px;
}

.rowButtonTextFooter {
  font-family: $font-primary;
  font-size: 20px;
  line-height: 29px;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .rowButtonTextFooter {
    font-size: 20px;
    line-height: 25px;
    color: white;
  }

  .rowButtonFooter {
    height: 60px;
    text-align: center;
    border-radius: 10px;
    border: 2px solid white;
    padding: 13px;
  }

  .footerMain {
    background-color: $primary;
    padding-top: 30px;
    padding-left: 0%;
    padding-right: 0%;
  }
  .logoFooter {
    height: 0px;
    width: 80px;
    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }
}
