.headerBlog {
  margin-top: 110px;
  margin-left: 35px;
  margin-right: 35px;
  // background-image: url("../../../../images/LT_Post_Paella.jpg");
  // @media screen and (max-width: 500px) {
  //   background-image: url("../../../../images/LT_Post_Paella_SM.jpg");
  // }
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px;
  @media screen and (max-width: 500px) {
    height: 315px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
