@import "../../../../scss/_variables";

.post {
    margin-bottom: 30px;
    
}

.card {
    background-color: white !important;
    border-radius: 12px !important;
    border: 1px solid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card-title {
    text-align: left;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 16px;
    color: $primary;
}

.card-subtitle {
    text-align: right;
    font-family: $font-secondary;
    font-size: 10px;
    line-height: 10px;
    color: $primary;
}